import { v4 as uuid_v4 } from 'uuid';

export const SessionCorrelationIdHeaderName = 'x-visma-sessionid';
export const CallCorrelationIdHeaderName = 'x-visma-callid';

export function vismaSessionId(): string {
  let sessionId = window.sessionStorage.getItem(SessionCorrelationIdHeaderName);
  if (!sessionId) {
    sessionId = uuid_v4();

    // some browsers do not allow storing anything in private mode
    // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem
    try {
      window.sessionStorage.setItem(SessionCorrelationIdHeaderName, sessionId);
    } catch {
      sessionId = 'notSupported';
    }
  }

  return sessionId;
}

export function vismaCallId(): string {
  return uuid_v4();
}
