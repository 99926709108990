import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable, InjectionToken, signal } from '@angular/core';
import {
  Observable,
  Subject,
  Subscription,
  filter,
  map,
  repeat,
  retry,
  single,
  tap,
  timer,
} from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { v4 as uuid_v4 } from 'uuid';

import {
  WsClientMessageDto,
  WsClientPayloadEnum,
  WsClientPayloadSchema,
} from '../models/ws/ws-client-message.dto';
import {
  WsServerMessageDto,
  WsServerPayloadEnum,
  WsServerPayloadSchema,
} from '../models/ws/ws-server-message.dto';
import { vismaCallId, vismaSessionId } from '../utils/visma-correlation-id';

export const WS_BASE_URL = new InjectionToken<string>('WS_BASE_URL');

@Injectable({
  providedIn: 'root',
})
export class WebSocketClient {
  private readonly odpOrgId: string;

  private socketSubject: WebSocketSubject<WsServerMessageDto | WsClientMessageDto>;
  private socketSubscription: Subscription;

  private pingSubscription: Subscription;
  private reconnectSubscription: Subscription;

  private readonly outerStreamSubject: Subject<WsServerMessageDto> = new Subject();
  private readonly outerStream$ = this.outerStreamSubject.asObservable();

  public readonly isLoggingEnabled = signal<boolean>(false);

  constructor(
    @Inject(WS_BASE_URL) private wsBaseUrl: string,
    @Inject(APP_BASE_HREF) appBaseHref: string,
  ) {
    this.odpOrgId = appBaseHref.replace(/\D/g, '');

    this.initNewConnection();
  }

  getFilteredStream(...payloadTypes: WsServerPayloadEnum[]): Observable<WsServerPayloadSchema> {
    let observable = this.outerStream$;

    if (payloadTypes.length >= 0) {
      observable = this.outerStream$.pipe(
        filter((message: WsServerMessageDto) => payloadTypes.includes(message.payload.type)),
      );
    }

    return observable.pipe(map((message) => message.payload));
  }

  getMessagesByType<T extends WsServerPayloadEnum>(
    payloadType: T,
  ): Observable<WsServerPayloadTypeToValueType<T>> {
    let observable = this.outerStream$;

    observable = this.outerStream$.pipe(
      filter((message: WsServerMessageDto) => message.payload.type === payloadType),
    );

    return observable.pipe(
      map((message) => message.payload.value as WsServerPayloadTypeToValueType<T>),
    );
  }

  private initNewConnection() {
    const newWebSocket = webSocket<WsServerMessageDto | WsClientMessageDto>({
      url: `${this.wsBaseUrl}/messages?org=${
        this.odpOrgId
      }&session=${vismaSessionId()}&call=${vismaCallId()}`,
      protocol: 'messages',
      openObserver: {
        next: () => {
          if (this.socketSubject) {
            this.closeConnection();
          }

          this.socketSubject = newWebSocket;
          this.socketSubscription = newSocketSubscription;

          this.startPingTimer();
          this.startReconnectTimer();
        },
      },
    });

    const socketObservable = newWebSocket.pipe(
      repeat(),
      retry({
        count: 10,
        resetOnSuccess: true,
        delay: (err, count) => timer(count * 1000),
      }),
      tap((message) => {
        if (this.isLoggingEnabled()) {
          console.log(message.payload.type, message.payload.value);
        }
      }),
    );

    const newSocketSubscription = socketObservable.subscribe({
      next: (result) => this.outerStreamSubject.next(result as WsServerMessageDto),
      error: (err) => this.outerStreamSubject.error(err),
    });
  }

  private send(payload: WsClientPayloadSchema): void {
    this.socketSubject.next({
      messageId: uuid_v4(),
      createdTimeIso: new Date().toISOString(),
      payload,
    });
  }

  private closeConnection(): void {
    this.socketSubscription.unsubscribe();
    this.socketSubject.complete();
  }

  private startPingTimer() {
    if (this.pingSubscription) {
      this.pingSubscription.unsubscribe();
    }

    // AWS expects to see socket activity every 10 mins. We do it every 2 mins.
    const pingInterval = 2 * 60 * 1000;

    this.pingSubscription = timer(pingInterval, pingInterval).subscribe(() => {
      this.send({
        type: WsClientPayloadEnum.Ping,
        value: { echoMessage: '¯\\_(ツ)_/¯' },
      });
    });
  }

  private startReconnectTimer() {
    if (this.reconnectSubscription) {
      this.reconnectSubscription.unsubscribe();
    }

    // AWS has a 2-hour hard limit for socket connection. JS background processing is fiddly so we set a timer by time.
    const currentDate = new Date();
    const startOfNextMinute = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      currentDate.getHours() + 1,
      currentDate.getMinutes() + 55,
      currentDate.getSeconds(),
    );

    this.reconnectSubscription = timer(startOfNextMinute)
      .pipe(single())
      .subscribe(() => this.initNewConnection());
  }
}

type WsServerPayloadTypeToValueType<T extends WsServerPayloadSchema['type']> =
  T extends WsServerPayloadSchema['type']
    ? Extract<WsServerPayloadSchema, { type: T }>['value']
    : never;
